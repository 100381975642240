import { Box, Title } from "./style";


const DebugConsole = ({ render }) => {

  return (
    <Box>
      <Title>DEBUG CONSOLE</Title>
      {render}
    </Box>
  );

}

export default DebugConsole;