import logo from './logo.svg';
import './App.css';
import Home from './screens/Home/Home';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDa6PcO9PPNwMAD4UfOZtzqmTjVGQ_54hk",
  authDomain: "xceptionin.firebaseapp.com",
  projectId: "xceptionin",
  storageBucket: "xceptionin.appspot.com",
  messagingSenderId: "431602672372",
  appId: "1:431602672372:web:7955547f42f05cd9c634a1",
  measurementId: "G-EV5GBGC2G8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



function App() {
  return (
    <Home/>
  );
}

export default App;
