import styled from "styled-components";
import colors from "../../styles/colors";


export const Box = styled.div`
  background-color: ${colors.dark3};
  padding: 5px 2rem;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;