import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import Lines from "../Lines";
import { Box } from "./style";
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useEffect, useRef, useState } from 'react';
import RunButton from '../Button/RunButton';
import DebugConsole from '../DebugConsole';
import StatusBar from '../StatusBar';
import Text from '../Text';
import colors from '../../styles/colors';
const Editor = ({ handleDebug }) => {

  const [ text, setText ] = useState('');
  const [ focusTile, setFocusTile ] = useState('|');
  const [ debugText, setDebugText ] = useState();
  const [ success, setSuccess ] = useState();
  const [ error, setError ] = useState();

  const [ loading, setLoading ] = useState(false);

  const interval = useRef();
  const inputRef = useRef();

  useEffect(()=>{
    interval.current = setInterval(()=>{
      setFocusTile(prev => prev == '|' ? ' ' : '|');
    }, 700);

    return ()=>clearInterval(interval.current)
  }, []);

  useEffect(()=>{
    handleDebug(error, success);
  }, [success]);

  const renderDebug = (e) => {
    if(e){
      e.preventDefault();
    }

    inputRef.current.blur();

    setLoading(true);
    
    setTimeout(()=>{
      setDebugText([
        <Text key={'execution'} color={colors.orange}>/usr/bin/node ./sayHello.js</Text>
      ])
    }, 700);
    
    setTimeout(()=>{
      setDebugText(prev => [...prev,
        text.length == 0 ?
        <Text key={'result'} color={colors.red}>Process exited with code 1</Text>
        :
        <Text key={'result'} color={colors.blue}>Hello {text}!</Text>
      ]);
      if(text.length !== 0){
        setLoading(false);
        setSuccess(true);
      }
      setError(false);
    }, 1400);
    if(text.length == 0){
      setTimeout(()=>{
        setDebugText(prev => [...prev,
          <Text key={'error'} color={colors.textLight}>{'Uncaught Error: Name can’t be null!'}
          <br/>&nbsp;&nbsp;&nbsp;&nbsp;
          {`at sayHello (Exception in sayHello.js:6:19) at <anonymous>`}</Text>
        ])
        setLoading(false);
        setError(true);
        setSuccess(false);
      }, 2100);
    }
    
  }
  
  return (
    <Box className={error ? 'shake' : null} onClick={()=>inputRef.current.focus()}>
      <form onSubmit={renderDebug}>
      <input ref={inputRef} type={'text'} value={text} onChange={(e)=>setText(e.target.value)} style={{position: 'absolute', opacity: 0}} autoFocus={true}/>

      </form>
      <RunButton loading={loading} onPress={renderDebug}/>
      <SyntaxHighlighter showLineNumbers={true} customStyle={{paddingLeft: 0, margin: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 30, borderTopRightRadius: 30}} lineNumberStyle={{fontStyle: 'normal'}} language={'javascript'} style={oneDark}>
      {`'use strict';

const sayHello = (name) => {

  if(!name){
    throw new Error('Name can’t be null!');
  }

  console.log(\`Hello \$\{name\}\`);
}

sayHello('${text}${focusTile}');      // kaycklbr, 2 days ago • implement sayHello function`}
      </SyntaxHighlighter>
      <DebugConsole render={debugText}/>
      <StatusBar/>
    </Box>
  );
}

export default Editor;