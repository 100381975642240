import styled from "styled-components";
import colors from "../../styles/colors";

export const Wrapper = styled.section`
  background-color: ${colors.red};
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
`;