import styled from "styled-components";
import colors from "../../styles/colors";


export const Box = styled.div`
  background-color: ${colors.dark2};
  padding: 0 1rem 1rem 1rem;
`;

export const Title = styled.p`
  color: ${colors.textLight};
  font-size: 14px;
  margin-bottom: 1rem;
`;
