import styled from "styled-components";
import colors from "../../../styles/colors";


export const Container = styled.div`
  position: absolute;
  cursor: pointer;
  top: 5px;
  align-self: center;
  padding: 8px 15px;
  border-radius: 8px;
  background-color: ${colors.dark3};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  span {
    color: white;
  }

`;