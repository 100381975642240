import { Text } from "./style";

const Header = ({children}) => {
  return (
    <Text>
      {children}
    </Text>

  );
}

export default Header;