import { GitBranch, Instagram } from "react-feather";
import colors from "../../styles/colors";
import Line from "../Line";
import Text from "../Text";
import { Box } from "./style";


const StatusBar = () => {

  return (
    <Box>
      <Line style={{width: '100%'}} justify={'space-between'}>
        <Line gap={15}>
          <Line gap={5}>
            <GitBranch size={12} color={colors.textLight} />
            <Text>main</Text>
          </Line>
          <Line gap={5}>
            <Text>UTF-8</Text>
          </Line>
          <Line gap={5}>
            <Text>{'{ } JavaScript'}</Text>
          </Line>
        </Line>
        <Line gap={2} onClick={()=>window.open('https://instagram.com/kaycklbr', '_blank')} style={{cursor: 'pointer'}}>
          <Instagram size={12} color={colors.textLight} />
          <Text>@kaycklbr</Text>
        </Line>
      </Line>
    </Box>
  );

}

export default StatusBar;