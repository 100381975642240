import React, { useState } from 'react';
import Container from '../../components/Container';
import Editor from '../../components/Editor';
import Header from '../../components/Header';
import './styles.css';


const Home = () => {

  const [ success, setSuccess ] = useState();

  const handleDebug = (error, success) => {
    setSuccess(!error && success);
  }

  return (
    <Container className={success ? 'success' : ''}>

      <Header>
        xception.in
      </Header>

      <Editor handleDebug={handleDebug}/>
    </Container>
  );
}

export default Home;