import { Play } from "react-feather";
import colors from "../../../styles/colors";
import CircleSpinner from "../../Loader/CircleSpinner";
import { Container } from "./style";


const RunButton = ({ loading, onPress }) => {
  return (
    <Container onClick={onPress}>
      {loading ? 
      <CircleSpinner/>
      :
      <Play size={16} color={colors.green} />}
      <span>Run</span>
    </Container>
  );
}

export default RunButton;