const colors = {
  red: '#FF5B5B',
  dark: '#33323E',
  dark2: '#282830',
  dark3: '#212128',
  textDark: '#676576',
  textLight: '#ACAABF',
  green: '#8AD827',
  yellow: '#F8C923',
  blue: '#43B0FF',
  orange: '#FF981F',
};

export default colors;