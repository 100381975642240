import styled from "styled-components";
import colors from "../../styles/colors";


export const Box = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 700px;
  border-radius: 30px;
  box-shadow: 10px 10px 0px #00000020;

  @media screen and (max-width: 800px){
    width: 90%;
  }
`;